'use client';

import { getServerSession, type Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { useEffect, useState } from 'react';
interface AuthProviderProps {
  children: React.ReactNode;
}
export default function AuthProvider({
  children
}: AuthProviderProps) {
  const [session, setSession] = useState<Session | null>(null);
  useEffect(() => {
    (async () => {
      try {
        const sessionData = await getServerSession();
        sessionData ? setSession(sessionData) : setSession(null);
      } catch (error) {}
    })();
  }, []);
  return <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-component="AuthProvider" data-sentry-source-file="AuthProvider.tsx">{children}</SessionProvider>;
}