'use client';

import { useUpdateAccessToken } from '@/store/authorization-atom';
import React from 'react';
interface AccessTokenProviderProps {
  children: React.ReactNode;
}
export default function AccessTokenProvider({
  children
}: AccessTokenProviderProps) {
  useUpdateAccessToken();
  return <>{children}</>;
}