'use client';

import { useConnectSocialAccount } from '@/data/network/rest/user';
import { signOut, useSession } from 'next-auth/react';
import { useEffect } from 'react';
export const SocialConnectionChecker = () => {
  const {
    data: session,
    status
  } = useSession();
  const loading = status === 'loading';
  const {
    mutate: socialConnect,
    error
  } = useConnectSocialAccount();
  useEffect(() => {
    // is true when valid social login access token and provider is available in the session
    // but not authorize/logged in
    if (Boolean(session?.accessToken) && Boolean(session?.provider)) {
      socialConnect({
        provider: session.provider as string,
        providerId: session.providerId as string,
        extraData: session.token as string
      });
      void signOut({
        redirect: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  // When rendering client side don't display anything until loading is complete
  if (typeof window !== 'undefined' && loading) return null;
  return <div data-sentry-component="SocialConnectionChecker" data-sentry-source-file="SocialConnectionChecker.tsx">{error instanceof Error ? error.message : null}</div>;
};