'use client';

import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useState } from 'react';
interface QueryProviderProps {
  children: React.ReactNode;
  dehydratedState?: unknown;
}
export default function QueryProvider({
  children,
  dehydratedState
}: QueryProviderProps) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1
      }
    }
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="QueryProvider" data-sentry-source-file="query-provider.tsx">
      <Hydrate state={dehydratedState} data-sentry-element="Hydrate" data-sentry-source-file="query-provider.tsx">
        <JotaiProvider initialValues={[[queryClientAtom, queryClient]]} data-sentry-element="JotaiProvider" data-sentry-source-file="query-provider.tsx">
          {children}
        </JotaiProvider>
      </Hydrate>
      <ReactQueryDevtools data-sentry-element="ReactQueryDevtools" data-sentry-source-file="query-provider.tsx" />
    </QueryClientProvider>;
}