'use client';

import { PayPalScriptProvider, type ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
interface PayPalProviderProps {
  children: React.ReactNode;
}
export default function PayPalProvider({
  children
}: PayPalProviderProps) {
  const initialOptions: ReactPayPalScriptOptions = {
    clientId: process.env.PAYPAL_CLIENT_ID as string,
    intent: 'subscription',
    vault: true,
    currency: 'USD'
  };
  return <PayPalScriptProvider options={initialOptions} data-sentry-element="PayPalScriptProvider" data-sentry-component="PayPalProvider" data-sentry-source-file="PayPalProvider.tsx">
      {children}
    </PayPalScriptProvider>;
}